.ua {
    display: none !important;
}

/* ==== Google font ==== */
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic|Montserrat:700|Merriweather:400italic');

body {
    width: 100%;
    height: 100%;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #666;
    background-color: #fff;
    overflow-x:hidden;
}

html {
    width: 100%;
    height: 100%;
}

body.home {
    background-color: #e8e6e7;
}

html.home {
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 30px;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

p {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 1.6em;
}

p.lead {
    font-weight: 600;
}

a {
    color: #28c3ab;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #0590c7;
}

a:hover {
    cursor: pointer;
}

.light {
    font-weight: 400;
}

.navbar {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    position: relative;
    height: auto;
    padding: 0 15px;
    font-size: 18px;
    line-height: 1em;
}

@media (max-width: 549px) {
    #about.home-section {
        padding-top: 200px;
    }
}

.navbar-logo {
    background: url(../img/kyongki.png) no-repeat top center;
    background-size: contain;
    width: 50px;
    height: 50px;
}

.navbar-brand h1 {
    text-shadow: -1px 1px 1px #000;
    color: #000;
    line-height: 1;
    margin: 0 0 0 10px;
    text-align: center;
    font-size: 18px;
}

.top-nav-collapse .navbar-brand h1 {
    color: #fff;
}

@media (max-width: 768px) {
    .navbar-brand h1 {
        color: #fff;
    }
}

@media (max-width: 494px) {
    .navbar-brand h1 {
        margin: 0 0 0 5px;
    }
}

.navbar-brand:focus {
    outline: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 1199px) {
    .navbar-collapse.navbar-right.navbar-main-collapse {
        position: absolute;
        right: 0;
    }

    #contact .widget-contact {
        margin: 30px 0;
    }
}

.nav.navbar-nav {
    background-color: rgba(255, 255, 255, .4);
    margin: 0;
}

.navbar-custom.top-nav-collapse .nav.navbar-nav {
    background-color: rgba(0, 0, 0, 0);
}

.navbar-custom ul.nav li a {
    font-size: 12px;
    letter-spacing: 1px;
    color: #444;
    text-transform: uppercase;
    font-weight: 700;
}

.navbar-custom.top-nav-collapse ul.nav li a {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #fff;
}

.navbar-custom ul.nav ul.dropdown-menu {
    border-radius: 0;
}

.navbar-custom ul.nav ul.dropdown-menu li {
    border-bottom: 1px solid #f5f5f5;

}

.navbar-custom ul.nav ul.dropdown-menu li:last-child {
    border-bottom: none;
}

.navbar-custom ul.nav ul.dropdown-menu li a {
    padding: 10px 20px;
}

.navbar-custom ul.nav ul.dropdown-menu li a:hover {
    background: #9DCDE1;
}

.navbar-custom.top-nav-collapse ul.nav ul.dropdown-menu li a {
    color: #666;
}

.navbar-custom .nav li a {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li.active {
    outline: 0;
    background-color: rgba(255, 255, 255, .2);
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li.active {
    outline: 0;
    background-color: rgba(255, 255, 255, .2);
}

.navbar-toggle {
    margin-top: 0;
    margin-bottom: 0;
    padding: 4px 6px;
    font-size: 30px;
    color: #fff;
    z-index: 10;
}

.navbar-toggle:focus,
.navbar-toggle:active {
    outline: 0;
}

/* loader */
#preloader {
    background: #ffffff;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
}

#loaderInner {
    background: #ffffff url(../img/spinner.gif) center center no-repeat;
    height: 60px;
    left: 50%;
    margin: -50px 0 0 -50px;
    position: absolute;
    top: 50%;
    width: 60px;
}

@keyframes boxSpin {
    0% {
        transform: translate(-10px, 0px);
    }
    25% {
        transform: translate(10px, 10px);
    }
    50% {
        transform: translate(10px, -10px);
    }
    75% {
        transform: translate(-10px, -10px);
    }
    100% {
        transform: translate(-10px, 10px);
    }
}

@keyframes shadowSpin {
    0% {
        box-shadow: 10px -10px #39CCCC, -10px 10px #FFDC00;
    }
    25% {
        box-shadow: 10px 10px #39CCCC, -10px -10px #FFDC00;
    }
    50% {
        box-shadow: -10px 10px #39CCCC, 10px -10px #FFDC00;
    }
    75% {
        box-shadow: -10px -10px #39CCCC, 10px 10px #FFDC00;
    }
    100% {
        box-shadow: 10px -10px #39CCCC, -10px 10px #FFDC00;
    }
}

#load {
    z-index: 9999;
    background-color: #FF4136;
    opacity: 0.75;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    margin: -5px auto 0 auto;
    left: 0;
    right: 0;
    border-radius: 0px;
    border: 5px solid #FF4136;
    box-shadow: 10px 0px #39CCCC, 10px 0px #01FF70;
    animation: shadowSpin 1s ease-in-out infinite;
}

/* misc */
hr {
    margin-top: 10px;
}

/* margins */
.marginbot-0 {
    margin-bottom: 0 !important;
}

.marginbot-10 {
    margin-bottom: 10px !important;
}

.marginbot-20 {
    margin-bottom: 20px !important;
}

.marginbot-30 {
    margin-bottom: 30px !important;
}

.marginbot-40 {
    margin-bottom: 40px !important;
}

.marginbot-50 {
    margin-bottom: 50px !important;
}

/* ===========================
--- General sections
============================ */

.home-section {
    padding-top: 90px;
    padding-bottom: 110px;
    display: block;
    position: relative;
    z-index: 120;
}

.section-heading h2 {
    font-size: 40px;
}

.section-heading i {
    margin-bottom: 20px;
}

/* --- section bg var --- */

.bg-white {
    background: #fff;
}

.bg-gray {
    background: #f8f8f8;
}

.bg-dark {
    background: #575757;
}

/* --- section color var --- */

.text-light {
    color: #fff;
}

/* ===========================
--- Intro
============================ */

.intro {
    width: 100%;
    position: relative;
    background: url(../img/bg1.jpg) no-repeat top center;
    background-size: cover;
    height: auto;
}

#intro.intro {
    padding: 50% 0 0 0;
    margin-bottom: -50%;
}

.home-page #intro.intro {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    #intro.intro {
        padding: 0;
    }
}

.intro .slogan {
    position: absolute;
    text-align: right;
    bottom: 5%;
    right: 5%;
}

.intro .page-scroll {
    margin: 0 0 30px 30px;
    position: absolute;
    bottom: 0;
}

.brand-heading {
    font-size: 40px;
}

.intro-text {
    font-size: 18px;
}

.intro .slogan h2 {
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    background-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    text-transform: uppercase;
    border-width: 0px;
    border-color: #000;
    border-style: none;
    text-shadow: -1px 0 1px #000;
}

.intro .slogan h4 {
    text-shadow: -1px 1px 1px #000;
    margin-bottom: 10px;
}

@media (min-width: 1200px) {
    .intro .slogan {
        width: 25%;
    }
}

@media (max-width: 768px) {
    .intro .slogan {
        font-size: 16px;
        width: 50%;
        color: #fff;
        float: right;
    }
}

@media (max-width: 600px) {
    .intro .slogan {
        font-size: 14px;
        width: 50%;
        color: #fff;
        float: right;
    }
}

/* ===========================
--- About
============================ */

.boxed-grey {
    background: #f9f9f9;
    padding: 20px;
}

.team h5 {
    height: 60px;
    margin-bottom: 10px;
}

.team p.subtitle {
    margin-bottom: 10px;
}

.avatar {
    margin-bottom: 20px;
}

.team-social {
    margin-left: 0;
    padding-left: 0;

}

.team-social {
    text-align: center;
}

.team-social li {
    display: inline-block;
    margin: 0 !important;
    padding: 0;
}

.team-social a {
    margin: 0;
    padding: 0;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3bbec0;
    color: #fff;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.team-social a i {
    text-align: center;
    margin: 0;
    padding: 0;
}

.team-social .social-facebook a {
    background: #3873ae;
}

.team-social .social-twitter a {
    background: #62c6f8;
}

.team-social .social-dribble a {
    background: #d74980;
}

.team-social .social-yt a {
    background: #d74980;
}

.team-social .social-deviantart a {
    background: #8da356;
}

.team-social .social-google a {
    background: #000;
}

.team-social .social-vimeo a {
    background: #51a6d3;
}

.team-social .social-facebook a:hover {
    background: #4893ce;
}

.team-social .social-twitter a:hover {
    background: #82e6ff;
}

.team-social .social-dribble a:hover {
    background: #f769a0;
}

.team-social .social-yt a:hover {
    background: #f769a0;
}

.team-social .social-deviantart a:hover {
    background: #adc376;
}

.team-social .social-google a:hover {
    background: #333;
}

.team-social .social-vimeo a:hover {
    background: #71c6f3;
}

.home-section .inner p {
    height: 130px;
}

@media (min-width: 767px) and (max-width: 991px) {
    .team.boxed-grey {
        margin: 30px 0;
    }
}

@media (min-width: 992px) {
    .team.boxed-grey {
        margin: 0 2px;
    }

    .col-md-3 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* ===========================
--- Services
============================ */

.service-icon {
    margin-bottom: 20px;
}

/* ===========================
--- Contact
============================ */

form#contact-form .form-group label {
    text-align: left !important;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
}

form#contact-form input, form#contact-form select, form#contact-form textarea {
    border-radius: 0;
    border: 1px solid #eee;
    -webkit-box-shadow: none;
    box-shadow: none;
    resize: vertical;
}

form#contact-form input:focus, form#contact-form select:focus, form#contact-form textarea:focus {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

form#contact-form .col-md-6 {
    padding: 0 20px;
}

form#contact-form .col-md-12 {
    padding: 0 25px;
    margin: -5px;
}

.input-group-addon {
    background-color: #fefefe;
    border: 1px solid #eee;
    border-radius: 0;
}

.widget-contact {
    text-align: left;
    margin: 0 30px;
}

.validation {
    color: red;
    display: none;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 13px;
}

#sendmessage {
    color: green;
    border: 1px solid green;
    display: none;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#errormessage {
    color: red;
    display: none;
    border: 1px solid red;
    text-align: center;
    padding: 15px;
    font-weight: 600;
    margin-bottom: 15px;
}

#sendmessage.show, #errormessage.show, .show {
    display: block;
}

.logo-itf-ul{
    display: flex;
    padding: 0;
    margin-top: 10px;
}

.logo-itf-li {
    display: flex;
}

.logo-itf-li img{
    height: 80px;
    margin-right: 5px;
}

.company-social {
    margin-left: 0;
    padding-left: 0;
    margin-top: 10px;
}

.company-social li {
    display: inline-block;
    margin: 0 !important;
    padding: 0 5px 0 0;
}

.company-social a {
    margin: 0;
    padding: 0;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3bbec0;
    color: #fff;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.company-social a i {
    text-align: center;
    margin: 0;
    padding: 0;
}

.company-social .social-facebook a {
    background: #3873ae;
}

.company-social .social-twitter a {
    background: #62c6f8;
}

.company-social .social-instagram a {
    background: #D32681;
}

.company-social .social-yt a {
    background: #d74980;
}

.company-social .social-deviantart a {
    background: #8da356;
}

.company-social .social-google a {
    background: #000;
}

.company-social .social-vimeo a {
    background: #51a6d3;
}

.company-social .social-facebook a:hover {
    background: #4893ce;
}

.company-social .social-twitter a:hover {
    background: #82e6ff;
}

.company-social .social-instagram a:hover {
    background: #f769a0;
}

.company-social .social-yt a:hover {
    background: #f769a0;
}

.company-social .social-deviantart a:hover {
    background: #adc376;
}

.company-social .social-google a:hover {
    background: #333;
}

.company-social .social-vimeo a:hover {
    background: #71c6f3;
}

/* ===========================
--- Footer
============================ */

footer {
    text-align: center;
    padding: 50px 0;

}

footer p {
    color: #f8f8f8;
}

.credits, .credits a {
    color: #fff;
}

/* ===========================
--- Elements
============================ */

.btn {
    border-radius: 0;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

#intro .btn-circle {
    border: 2px solid #666;
    color: #666;
}

.btn-circle {
    width: 70px;
    height: 70px;
    margin-top: 15px;
    padding: 7px 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 40px;
    color: #fff;
    background: 0 0;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.btn-circle.btn-dark {
    border: 2px solid #666;
    color: #666;
}

#intro .btn-circle:hover,
#intro .btn-circle:focus {
    outline: 0;
    color: #666;
    background: rgba(255, 255, 255, .1);
}

.btn-circle:hover,
.btn-circle:focus {
    outline: 0;
    color: #fff;
    background: rgba(255, 255, 255, .1);
}

.btn-circle.btn-dark :hover,
.btn-circle.btn-dark :focus {
    outline: 0;
    color: #999;
    background: #fff;
}

.btn-circle.btn-dark :hover i,
.btn-circle.btn-dark :focus i {
    color: #999;
}

.page-scroll .btn-circle i.animated {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
}

.page-scroll .btn-circle:hover i.animated {
    -webkit-animation-name: pulse;
    -moz-animation-name: pulse;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
}

/*@-webkit-keyframes pulse {

0
{
    -webkit-transform: scale(1)
;
    transform: scale(1)
;
}

50
%
{
    -webkit-transform: scale(1.2)
;
    transform: scale(1.2)
;
}

100
%
{
    -webkit-transform: scale(1)
;
    transform: scale(1)
;
}
}

@-moz-keyframes pulse {

0
{
    -moz-transform: scale(1)
;
    transform: scale(1)
;
}

50
%
{
    -moz-transform: scale(1.2);
    transform: scale(1.2);}
100%
{-moz-transform: scale(1);
    transform: scale(1);}
}*/

#text {
    color: #fff;
    background: #ffcc00;
}

#map {
    height: 500px;
}

.btn-skin:hover,
.btn-skin:focus,
.btn-skin:active,
.btn-skin.active {
    color: #fff;
    background-color: #666;
    border-color: #666;
}

.btn-default:hover,
.btn-default:focus {
    border: 1px solid #28c3ab;
    outline: 0;
    color: #000;
    background-color: #28c3ab;
}

.btn-huge {
    padding: 25px;
    font-size: 26px;
}

.banner-social-buttons {
    margin-top: 0;
}

/* Media queries */

@media (min-width: 992px) {
    #about .row:nth-child(even) {
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 767px) {

    .navbar {
        padding: 20px 0;
        border-bottom: 0;
        letter-spacing: 1px;
        background: 0 0;
        -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
        transition: background .5s ease-in-out, padding .5s ease-in-out;
    }

    .top-nav-collapse {
        padding: 0;
        background-color: #000;
    }

    .navbar-custom.top-nav-collapse {
        border-bottom: 1px solid rgba(255, 255, 255, .3);
    }

    .intro {
        height: 100%;
        padding: 0;
    }

    .brand-heading {
        font-size: 100px;
    }

    .intro-text {
        font-size: 25px;
    }

}

@media (max-width: 768px) {
    .home .heading-about {
        padding-top: 0;
    }

    #service .heading-about {
        padding-top: 125px;
    }

    .team.boxed-grey {
        margin-bottom: 30px;
    }

    .boxed-grey {
        margin: 30px;
    }

}

@media (max-width: 480px) {

    .navbar-custom .nav.navbar-nav {
        background-color: rgba(255, 255, 255, .4);
    }

    .navbar.navbar-custom.navbar-fixed-top {
        margin-bottom: 30px;
    }

    .tp-banner-container {
        padding-top: 40px;
    }

    .page-scroll a.btn-circle {
        width: 40px;
        height: 40px;
        margin-top: 10px;
        padding: 7px 0;
        border: 2px solid #fff;
        border-radius: 50%;
        font-size: 20px;
    }

}

/*Taekwondo page*/

.menu-page.intro {
    filter: blur(3rem);
}

.service-desc:not(h5) {
    text-align: justify;
}

.service-desc h5 {
    text-align: center;
}

.home-section .service-desc p {
    text-align: left;
}

#service.home-section .container {
    margin-bottom: 30px;
}

#service.home-section .container .service-desc:not(h5) {
    font-size: 16px;
    line-height: 1.6em
}

.bg-transparent {
    width: 100%;
    background-color: transparent;
    color: black;
    /*top: -800px;*/
    /*margin-bottom: -900px;*/
}

@media (max-width: 766px) {
    .bg-transparent {
        width: auto;
        top: 50px;
        margin: 0 15px;
    }

    #service .row:nth-child(even) {
        margin-right: auto;
        margin-left: auto;
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 560px) and (max-width: 566px) {
    .bg-transparent {
        top: 100px;
        margin: 0 15px;
    }
}

.col-main {
    width: 100%;
}

.img-content {
    margin: auto;
    display: block;
    max-width: 100%;
}

.container video {
    display: block;
    margin: 0 auto 1px auto;
    max-width: 100%;
    height: auto;
}

.container iframe {
    display: block;
    margin: 0 auto 1px auto;
    max-width: 100%;
    height: calc(50vw);
    max-height: 450px;
}

.team a {
    color: black;
}

#contact > .container .col-lg-8 {
    display: none;
}

.contactForm label {
    float: left;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    #service.home-section .container {
        margin-top: 100px;
    }
}
@media (max-width: 947px) and (orientation: landscape) {
    .bg-transparent {
        margin-top: 100px;
    }
}

.map-google {
    width: auto;
    min-width: 600px;
    height: 280px;
}

.contact-with-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 992px) {
    .map-google {
        min-width: auto;
    }

    .contact-with-us {
        justify-content: space-around;
    }
}

@media (max-width: 640px) {
    .contact-with-us {
        flex-direction: column;
    }

    .widget-contact {
        display: flex;
        justify-content: space-between;
    }

    .contact-items {
        margin-right: 15px;
    }
    .logo-itf-li img {
        height: 46px;
    }
}

.tul-with-margin,
.belt-with-margin {
    margin-top: -100px;
    position: absolute;
}

.attestation-list b {
    color: #000000;
}

.audio-holder {
    height: 150px;
}

.audio {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.icon-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    right: 0;
    margin: auto;
}

.error {
    text-align: center;
    position: relative;
    padding-top: 110px;
    padding-bottom: 110px;
}
.error:before {
    background: url(../img/404/404.svg) no-repeat 50%;
    width: 535px;
    height: 293px;
    content: "";
    display: block;
    margin: 0 auto 75px;
}